import { Slot } from "@radix-ui/react-slot";
import React from "react";

import { cn } from "~/utilities/cn";

type ButtonVariants =
  | "default"
  | "destructive"
  | "outline"
  | "white"
  | "ghost"
  | "transparent"
  | "link"
  | "round"
  | "roundShadow"
  | "text"
  | "disabled"
  | "tab"
  | "success";

type ButtonSizes =
  | "link"
  | "default"
  | "small"
  | "medium"
  | "large"
  | "iconSmall"
  | "icon"
  | "wide"
  | "full"
  | "wideMobile";

const variants: Record<ButtonVariants, string> = {
  default: `btn bg-color text-white border-none hover:bg-color hover:border-none hover:opacity-60 duration-200  active:hover:bg-color active:hover:opacity-100`,
  destructive:
    "btn bg-error border-none text-white hover:bg-error hover:border-inherit hover:opacity-50 duration-200",
  outline:
    "btn bg-white border border-color hover:bg-white hover:border-color hover:bg-opacity-100 hover:text-opacity-50",
  white:
    "btn bg-white border-inherit hover:bg-white hover:shadow-sm hover:border-inherit",
  ghost: "btn bg-gableGreen25",
  transparent:
    "btn bg-transparent border-none hover:bg-transparent shadow-none",
  link: "btn btn-link",
  round: "round-full bg-white text-color",
  roundShadow: "!shadow-sm round-full bg-white text-color",
  text: "btn bg-transparent hover:bg-transparent hover:text-color border-none shadow-none",
  disabled:
    "btn bg-gableGreen25 border-inherit hover:bg-gableGreen25 hover:border-inherit text-white",
  tab: "bg-color hover:bg-color text-white",
  success: "bg-successGreen border-successGreen shadow-sm",
};

const sizes: Record<ButtonSizes, string> = {
  link: "py-0 px-0",
  default: "py-3 px-6",
  small: "py-1 px-3",
  medium: "py-3 px-12",
  large: "py-3 px-24",
  iconSmall: "p-1.5",
  icon: "p-3",
  wide: "py-2 px-5",
  full: "btn-full",
  wideMobile: "px-3 py-1 rounded-[17.5px]",
};

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariants;
  size?: ButtonSizes;
  asChild?: boolean;
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      asChild,
      children,
      className = "",
      variant = "default",
      size = "default",
      isLoading = false,
      ...props
    },
    ref
  ) => {
    const initialClass = `group inline-flex items-center justify-center gap-3 whitespace-nowrap h-auto min-h-0 rounded-[21.5px] font-bold transition-colors disabled:cursor-not-allowed disabled:border-none disabled:opacity-8 fit-content duration-200`;

    const buttonClass = cn(initialClass, variants[variant], sizes[size]);

    const Comp = asChild ? Slot : "button";

    return (
      <Comp className={cn(buttonClass, className)} ref={ref} {...props}>
        {isLoading && <p className="loading loading-spinner w-[14px]"></p>}
        {children}
      </Comp>
    );
  }
);

Button.displayName = "Button";

export default Button;
